import '../../styles/main.scss';
import { faHome, faLeft } from '@fortawesome/pro-regular-svg-icons';
import { Button, Heading, Image, Text } from '@quality24/design-system';
import { HeadFC, navigate } from 'gatsby';
import classNames from 'classnames';
import * as React from 'react';

import SEO from '../../components/containers/SEO';
import MainTemplate from '../../components/templates/MainTemplate';
import SquareGraphic from '../../assets/graphic-1.inline.svg';
import image from '../../assets/thank-you.svg';

import * as styles from './thank-you.module.scss';

const ThankYouPage: React.FunctionComponent = () => (
  <MainTemplate>
    <div className="container my-5">
      <div className={classNames(styles.wrapper, 'row g-4')}>
        <div className="col-12 col-lg-4 d-flex flex-justify-center">
          <Image
            className={styles.image}
            src={image}
            alt="Ilustração de uma mulher trabalhando no computador"
            fluid
          />
        </div>
        <div className={classNames(styles.textSection, 'col-12 col-lg-8')}>
          <Heading
            as="h1"
            className="mb-3"
            color="secondary"
            size="lg2"
            weight="bold"
          >
            Obrigado!
          </Heading>
          <Text className="mb-3" size="lg2">
            Você deu o primeiro passo na direção da transformação da experiência
            de seus pacientes e de seus processos!
          </Text>
          <Text className="mb-3" size="lg2">
            Em breve entraremos em contato para discutirmos os próximos passos.
          </Text>

          <div className="d-flex gap-3 mt-5">
            <Button
              variant="outline"
              size="lg"
              icon={faLeft}
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>
            <Button size="lg" icon={faHome} onClick={() => navigate('/')}>
              Início
            </Button>
          </div>
        </div>
      </div>
    </div>

    <SquareGraphic className={styles.graphic} />
  </MainTemplate>
);

export default ThankYouPage;

export const Head: HeadFC = () => <SEO title="Obrigado" description="" />;
